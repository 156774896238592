import React, { Suspense, lazy, useContext, useEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";

// import ConfirmationPage from "pages/confirmation_page";
// import HomePage from "pages/home_page";
// import HotelPage from "pages/hotel_page";
// import NotFoundPage from "pages/not_found_page";
// import PaymentPage from "pages/payment_page";
// import SearchPage from "pages/search_page";

import buildPath from "utils/build_path";
import getUrlParams from "utils/get_url_params";

import routes from "./routes";
import { BookingDataContext } from "containers/data_context";
import ApiActions from "api_actions";
import ReactGA from "react-ga4";

// Lazy-loaded components
const ConfirmationPage = lazy(() => import("pages/confirmation_page"));
const HomePage = lazy(() => import("pages/home_page"));
const HotelPage = lazy(() => import("pages/hotel_page"));
const NotFoundPage = lazy(() => import("pages/not_found_page"));
const PaymentPage = lazy(() => import("pages/payment_page"));
const SearchPage = lazy(() => import("pages/search_page"));
const UpdateBookingPage = lazy(() => import("pages/update_booking_page/update_booking_page"));

export default function Routing() {
  const history = useHistory();

  useEffect(
    function handleChannelRedirect() {
      const { channel } = getUrlParams();

      if (!channel) {
        return;
      }

      const hotelUrl = buildPath("", routes.hotelPage, { channelId: channel });

      history.replace(hotelUrl);
    },

    [history],
  );

  const { channelId } = useContext(BookingDataContext);
  document.body.style.backgroundImage = `url("${process.env.REACT_APP_HOTEL_POINT_URL}/api/engine-bg/${channelId}")`;
  useEffect(() => {
    if (channelId) {
      const getData = async () => {
        const data = await ApiActions?.getProperty(channelId);

        if (data?.data && data?.data[0]?.google_analytics_key) {
          ReactGA.initialize(data?.data[0]?.google_analytics_key);
        }
      };
      getData();
    }
  }, [channelId]);
  return (
    <Suspense fallback={<div>Loading</div>}>
      <Switch>
        <Route path={routes.default} exact>
          <NotFoundPage />
        </Route>

        <Route path={routes.homePage} exact>
          <SearchPage />
        </Route>
        <Route path={routes.hotelPage} exact>
          <HotelPage />
        </Route>
        <Route path={routes.paymentPage} exact>
          <PaymentPage />
        </Route>
        <Route path={routes.confirmationPage} exact>
          <ConfirmationPage />
        </Route>
        <Route path={routes.updateBookingPage} exact>
          <UpdateBookingPage />
        </Route>
        <Route path="*">
          <Redirect to={routes.default} />
        </Route>
      </Switch>
    </Suspense>
  );
}
